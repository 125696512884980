@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap);
:root {
    --titleColor:#1A2530;
    --paragraphColor: #363E45;
    --iconColor: #8692A3;
    --grayColor: #ABAFB9;
    --elementsColor:#E8E8E8;
    --backgroundColor:#FBFFFF;
    --borderColor:#C4C4C4;
    --blueColor:#006496;
    --greenColor:#68DDB0;
    --lightGreenColor:rgba(104, 221, 176, 0.2);
    
    --yellowColor:#F7BF44;
    --lightYellowColor:rgba(247, 191, 68, 0.2);

    --redColor:#DA4655;
    --lightRedColor:rgba(218, 70, 85, 0.18);

    --orangeColor:#E16D4F;
    --purpleColor:"";
    

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*  styling scrollbars  */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--grayColor);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

* {
  margin: 0;
  padding: 0;
}

.container {
  display: grid;
  height: 100vh;
  padding: 0px;
  grid-template-columns: 0.5fr 3fr;
  /* grid-template-columns: 0.5fr 1fr 1fr 1fr; */
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'sidebar nav nav nav'
    'sidebar main main main';
  max-width: 100%;
}

@media only screen and (max-width: 978px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'nav'
      'main';
  }
}

.DashMarker {
  cursor: pointer;
}

/* Marker CSS */
.DashMarkerLabel {
  border-radius: 8px;
  background: var(--blueColor);
  padding: 8px 16px;
  display: inline-block;
  width: auto;
  min-width: 50px;
  height: 32px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 12px;
  font-family: 'Open Sans';
  position: absolute;
  white-space: nowrap;
  left: 8px;
  top: 8px;
}

.DashMarkerDot {
  border-radius: 50%;
  background: var(--blueColor);
  border: 3px solid white;
  padding: 8px;
  width: 20px;
  height: 20px;
  position: absolute;
}

.MarkerDot {
  height: 15px;
  width: 15px;
  background-color: var(--blueColor);
  border-radius: 50%;
}

.MarkerBlack {
  background: black;
}

.MarkerDotInline {
  height: 20px;
  width: 20px;
  background-color: var(--blueColor);
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-top-20 {
  padding-top: 20px;
}

.error-popup{padding:1rem 2rem;width:100%;background-color:#a92e2e;height:100%;cursor:default}.error-popup .header{font-size:1rem;font-weight:900;color:white}.error-popup .message{color:#fff;font-size:1rem;font-weight:100}.error-popup .close-btn{color:#fff;display:flex;align-items:flex-end}.error-popup .close-btn i{margin-left:auto}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

html,
body,
#app,
#app > div {
  height: 100%;
}

.navbar {
  background: #ffffff;
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid var(--borderColor);
  border-left: 1px solid white;
  margin-left: -1px;

  /* -moz-box-shadow:    5px 0px 5px 0 var(--grayColor);
  -webkit-box-shadow: 5px 0px 5px 0 var(--grayColor);
  box-shadow:         5px 0px 5px 0 var(--grayColor); */
}

.navIcon {
  display: none;
}

.navIcon > i {
  font-size: 26px;
  color: var(--iconColor);
}

.navbarRight {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.navbarRight > a {
  margin-left: 20px;
  text-decoration: none;
  color: var(--paragraphColor);
  font-size: 14px;
  font-weight: normal;
}

.navbarLogoutBox {
  width: 100px;
  height: 50px;
  position: absolute;
  right: 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 10px 70px 0 rgb(0 0 0 / 25%);
  padding-top: 12.5px;
  text-align: center;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 10000;
}
.navbarLogoutBox:hover {
  background-color: lightgrey;
}

.navbarRight > a > i {
  color: var(--iconColor);
  font-size: 16px;
}

@media only screen and (max-width: 978px) {
  .navIcon {
    display: inline;
  }

  .btn-top{
    white-space: nowrap;
    font-size: 0.7rem;
    position: absolute;
    left: 20%;
    top: 0.9rem;
    font-weight: 800;
  }
}

@media only screen and (max-width: 480px) {
  .navbarLeft {
    display: none;
  }
}

.modal-comp-switch{position:absolute;margin:10% 30%;width:40%;background-color:#fff;border:none;border-radius:10px;padding:1rem;padding-bottom:3rem;outline:none;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.modal-comp-switch .search-bar{border:1px solid #d9d9d9;width:86%;margin:1rem auto;color:grey;display:flex}.modal-comp-switch .search-bar input{width:95%;padding:0.5rem 1rem;outline:none;font-weight:800;color:#262626;border:none}.modal-comp-switch .search-bar i{margin:auto 0}.modal-comp-switch .btn{background-color:#006496;border-radius:10px;color:white;border:none;text-decoration:none;font-size:1rem;font-weight:600;padding:9px 20px;vertical-align:middle;white-space:nowrap}.modal-comp-switch .comp-list-container{border:1px solid #d9d9d9;height:15rem;margin:0 3rem;margin-top:2rem;font-size:1rem;font-weight:900;overflow-y:scroll;padding:0.5rem 0.9rem}.modal-comp-switch .Title{font-size:1.2rem;font-weight:900;text-align:center}.modal-comp-switch .btn-close{display:flex;justify-content:flex-end}.modal-comp-switch .btn-close .close-icon{color:#a4a4a4;font-size:1.3rem;padding:0.3rem 0.5rem}.modal-comp-switch .btn-container{display:flex;justify-content:space-around}.modal-comp-switch .f-icon{padding-right:10px;font-size:1.1rem}.Overlay-comp{position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(50,50,51,0.8)}@media (max-width: 576px){.modal-comp-switch{width:90%;margin:10rem 5%;padding:1rem 0.4rem}.modal-comp-switch .search-bar input{padding:0.3rem 0.8rem}.modal-comp-switch .search-bar i{margin:auto 0.3rem}.modal-comp-switch .btn{font-size:0.9rem}.modal-comp-switch .comp-list-container{margin:0 auto;font-size:0.9rem;padding:0.5rem 0.9rem;width:90%}}

#sidebar {
  background: white;
  grid-area: sidebar;
  overflow-y: auto;
  height: 100vh;
  padding: 20px;
  transition: all 0.5s;
  border-right: 1px solid var(--borderColor);
  min-width: 233px !important;

  /* -moz-box-shadow:    -3px 0 10px 0 var(--grayColor);
  -webkit-box-shadow: -3px 0 10px 0 var(--grayColor);
  box-shadow:         -3px 0 10px 0 var(--grayColor); */
}

.sidebarTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--titleColor);
  margin-bottom: 20px;
}

.sidebarImage {
  display: flex;
  align-items: center;
}

.sidebarTitle > div > img {
  width: auto;
  height: 40px;
  object-fit: contain;
}

.sidebarTitle > div > h1 {
  font-size: 18px;
  display: inline;
}

.sidebarTitle > i {
  font-size: 18px;
  display: none;
  margin-left: auto;
}

.sidebarLink {
  color: var(--grayColor);
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 15px;
}

.sidebarActiveMenu {
  color: var(--blueColor);
}

.sidebarActiveMenu a {
  color: var(--blueColor) !important;
}

.sidebarLink > a {
  text-decoration: none;
  color: var(--grayColor);
  font-weight: bold;
  font-size: 16px;
}

.sidebarLink > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebarResponsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
  width: 240px;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
  }

  .sidebarTitle > i {
    display: inline;
  }
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option {
  width: 220px !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option {
  padding-left: 0.5rem !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-wrap
  .side-navigation-panel-select-option-text {
  margin-left: 0.6rem !important;
  margin-right: 0.3rem !important;
  padding-left: 0.1rem !important;
  /* width: 119px !important; */
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option {
  padding-left: 1.5rem !important;
}

.trailer-icon{
  color: #006496;
  font-size: 1.2rem;
}
