@import '../../cssVariables.css';

.navbar {
  background: #ffffff;
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid var(--borderColor);
  border-left: 1px solid white;
  margin-left: -1px;

  /* -moz-box-shadow:    5px 0px 5px 0 var(--grayColor);
  -webkit-box-shadow: 5px 0px 5px 0 var(--grayColor);
  box-shadow:         5px 0px 5px 0 var(--grayColor); */
}

.navIcon {
  display: none;
}

.navIcon > i {
  font-size: 26px;
  color: var(--iconColor);
}

.navbarRight {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.navbarRight > a {
  margin-left: 20px;
  text-decoration: none;
  color: var(--paragraphColor);
  font-size: 14px;
  font-weight: normal;
}

.navbarLogoutBox {
  width: 100px;
  height: 50px;
  position: absolute;
  right: 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 10px 70px 0 rgb(0 0 0 / 25%);
  padding-top: 12.5px;
  text-align: center;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 10000;
}
.navbarLogoutBox:hover {
  background-color: lightgrey;
}

.navbarRight > a > i {
  color: var(--iconColor);
  font-size: 16px;
}

@media only screen and (max-width: 978px) {
  .navIcon {
    display: inline;
  }

  .btn-top{
    white-space: nowrap;
    font-size: 0.7rem;
    position: absolute;
    left: 20%;
    top: 0.9rem;
    font-weight: 800;
  }
}

@media only screen and (max-width: 480px) {
  .navbarLeft {
    display: none;
  }
}
