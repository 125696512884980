:root {
    --titleColor:#1A2530;
    --paragraphColor: #363E45;
    --iconColor: #8692A3;
    --grayColor: #ABAFB9;
    --elementsColor:#E8E8E8;
    --backgroundColor:#FBFFFF;
    --borderColor:#C4C4C4;
    --blueColor:#006496;
    --greenColor:#68DDB0;
    --lightGreenColor:rgba(104, 221, 176, 0.2);
    
    --yellowColor:#F7BF44;
    --lightYellowColor:rgba(247, 191, 68, 0.2);

    --redColor:#DA4655;
    --lightRedColor:rgba(218, 70, 85, 0.18);

    --orangeColor:#E16D4F;
    --purpleColor:"";
    

}