.modal-comp-switch {
  position: absolute;
  margin: 10% 30%;
  width: 40%;
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 10px;
  padding: 1rem;
  padding-bottom: 3rem;
  outline: none;
  height: fit-content;

  .search-bar{
    border: 1px solid rgb(217, 217, 217);
    width: 86%;
    margin: 1rem auto;
    color: grey;
    display: flex;

    input{
      width: 95%;
      padding: 0.5rem 1rem;
      outline: none;
      font-weight: 800;
      color: rgb(38, 38, 38);
      border: none;
    }
    i {
      margin: auto 0;
    }
  }

  .btn {
    background-color: #006496;
    border-radius: 10px;
    color: white;
    border: none;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    padding: 9px 20px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .comp-list-container{
      border: 1px solid rgb(217, 217, 217);
      height: 15rem;
      margin: 0 3rem;
      margin-top: 2rem;
      font-size: 1rem;
      font-weight: 900;
      overflow-y: scroll;
      padding: 0.5rem 0.9rem;
  }

  .Title {
    font-size: 1.2rem;
    font-weight: 900;
    text-align: center;
  }

  .btn-close {
    display: flex;
    justify-content: flex-end;

    .close-icon {
      color: rgb(164, 164, 164);
      // font-weight: 600;
      font-size: 1.3rem;
      padding: 0.3rem 0.5rem;
    }
  }

  .btn-container {
    display: flex;
    justify-content: space-around;
  }

  .f-icon {
    padding-right: 10px;
    font-size: 1.1rem;
  }

}

.Overlay-comp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(50, 50, 51, 0.8);
}

@media (max-width : 576px) {
  .modal-comp-switch{
    width: 90%;
    margin: 10rem 5%;
    padding: 1rem 0.4rem;

    .search-bar{
      input{
        padding: 0.3rem 0.8rem;
      }    
      i{
        margin: auto 0.3rem;
      }  
    }

    .btn{
      font-size: 0.9rem;
    }

    .comp-list-container {
      margin: 0 auto;
      font-size: 0.9rem;
      padding: 0.5rem 0.9rem;
      width: 90%;
    }
  }
}
