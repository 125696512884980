@import './cssVariables.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

/*  styling scrollbars  */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--grayColor);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

* {
  margin: 0;
  padding: 0;
}

.container {
  display: grid;
  height: 100vh;
  padding: 0px;
  grid-template-columns: 0.5fr 3fr;
  /* grid-template-columns: 0.5fr 1fr 1fr 1fr; */
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'sidebar nav nav nav'
    'sidebar main main main';
  max-width: 100%;
}

@media only screen and (max-width: 978px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'nav'
      'main';
  }
}

.DashMarker {
  cursor: pointer;
}

/* Marker CSS */
.DashMarkerLabel {
  border-radius: 8px;
  background: var(--blueColor);
  padding: 8px 16px;
  display: inline-block;
  width: auto;
  min-width: 50px;
  height: 32px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 12px;
  font-family: 'Open Sans';
  position: absolute;
  white-space: nowrap;
  left: 8px;
  top: 8px;
}

.DashMarkerDot {
  border-radius: 50%;
  background: var(--blueColor);
  border: 3px solid white;
  padding: 8px;
  width: 20px;
  height: 20px;
  position: absolute;
}

.MarkerDot {
  height: 15px;
  width: 15px;
  background-color: var(--blueColor);
  border-radius: 50%;
}

.MarkerBlack {
  background: black;
}

.MarkerDotInline {
  height: 20px;
  width: 20px;
  background-color: var(--blueColor);
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-top-20 {
  padding-top: 20px;
}
