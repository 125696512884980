@import '../../cssVariables.css';

#sidebar {
  background: white;
  grid-area: sidebar;
  overflow-y: auto;
  height: 100vh;
  padding: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-right: 1px solid var(--borderColor);
  min-width: 233px !important;

  /* -moz-box-shadow:    -3px 0 10px 0 var(--grayColor);
  -webkit-box-shadow: -3px 0 10px 0 var(--grayColor);
  box-shadow:         -3px 0 10px 0 var(--grayColor); */
}

.sidebarTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--titleColor);
  margin-bottom: 20px;
}

.sidebarImage {
  display: flex;
  align-items: center;
}

.sidebarTitle > div > img {
  width: auto;
  height: 40px;
  object-fit: contain;
}

.sidebarTitle > div > h1 {
  font-size: 18px;
  display: inline;
}

.sidebarTitle > i {
  font-size: 18px;
  display: none;
  margin-left: auto;
}

.sidebarLink {
  color: var(--grayColor);
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 15px;
}

.sidebarActiveMenu {
  color: var(--blueColor);
}

.sidebarActiveMenu a {
  color: var(--blueColor) !important;
}

.sidebarLink > a {
  text-decoration: none;
  color: var(--grayColor);
  font-weight: bold;
  font-size: 16px;
}

.sidebarLink > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebarResponsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
  width: 240px;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
  }

  .sidebarTitle > i {
    display: inline;
  }
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option {
  width: 220px !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option {
  padding-left: 0.5rem !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-wrap
  .side-navigation-panel-select-option-text {
  margin-left: 0.6rem !important;
  margin-right: 0.3rem !important;
  padding-left: 0.1rem !important;
  /* width: 119px !important; */
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option {
  padding-left: 1.5rem !important;
}

.trailer-icon{
  color: #006496;
  font-size: 1.2rem;
}