.error-modal{
    // background-color: red;
}
.error-popup{
    // border: 1px solid red;
    padding: 1rem 2rem;
    width: 100%;
    background-color: rgb(169, 46, 46);
    // border-radius: 5px;
    height: 100%;
    cursor: default;

    .header{
        font-size: 1rem;
        font-weight: 900;
        color: white;
    }

    .message{
        color: #fff;
        font-size: 1rem;
        font-weight: 100;
    }
    .close-btn{
        // height: 1rem;
        color: #fff;
        display: flex;
        align-items: flex-end;
        // border: 1px solid white;
        // background-color: #fff;

        i{
            margin-left: auto;
        }
    }
}